import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import es from "./locales/es.json"
import en from "./locales/en.json"

const resources = {
    en: { translation: en },
    es: { translation: es }
};

i18n
    .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        fallbackLng: 'en',
        resources,
        load: 'languageOnly',
        cleanCode: true,
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;