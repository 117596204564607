import { useTranslation } from 'react-i18next';

function App() {
  const { t, i18n } = useTranslation();

  const changeLanguageHandler = (lang) => {
    i18n.changeLanguage(lang)
  }


  return (
    <>
      <div className="page-wrapper">
        {/* <!-- Preloader --> */}
        <div className="preloader"></div>
        {/* <!--====== Header Part Start ======--> */}
        <header className="main-header header-three">
          {/* <!--Header-Upper--> */}
          <div className="header-upper">
            <div className="container clearfix">
              <div className="header-inner py-20">
                <div className="logo-outer">
                  <div className="logo"><a href="index.html"><img src="assets/images/logos/tailorsoft-logo-white.png" alt="Logo" /></a></div>
                </div>

                <div className="nav-outer d-flex align-items-right">
                  {/* <!-- Main Menu --> */}
                  <nav className="main-menu navbar-expand-lg">
                    <div className="navbar-header">
                      <div className="logo-mobile"><a href="index.html"><img src="assets/images/logos/tailorsoft-logo-white.png" alt="Logo" /></a></div>
                      {/* <!-- Toggle Button --> */}
                      <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse" aria-controls="main-menu">
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                      </button>
                    </div>

                    <div className="navbar-collapse collapse clearfix" id="main-menu">
                      <ul className="navigation clearfix">
                        <li className="dropdown current">
                          <a href="ES" >
                            <div style={{ margin: "5px" }}>{i18n.language}</div>
                            {/* <img src="assets/images/icons/translate.svg" style={{width: "25px"}} alt="translate" /> */}
                          </a>

                          <ul>
                            {
                              /* eslint-disable */
                            }
                            <li onClick={() => changeLanguageHandler('en')}><a>en</a></li>
                            <li onClick={() => changeLanguageHandler('es')}><a>es</a></li>
                            {
                              /* eslint-enable */
                            }
                          </ul>
                        </li>
                      </ul>
                      {/* <ul className="navigation clearfix">
                        <li className="dropdown current"><a href="/jddj">home</a>
                          <ul>
                            <li><a href="index.html">Home One</a></li>
                            <li><a href="index2.html">Home Two</a></li>
                            <li><a href="index3.html">Home Three</a></li>
                            <li><a href="index3dark.html">Home 3 Dark</a></li>
                          </ul>
                        </li>
                        <li><a href="about.html">about</a></li>
                        <li className="dropdown"><a href="/jddj">project</a>
                          <ul>
                            <li><a href="projects.html">Projects</a></li>
                            <li><a href="project-details.html">Project details</a></li>
                          </ul>
                        </li>
                        <li className="dropdown"><a href="/jddj">blog</a>
                          <ul>
                            <li><a href="blog.html">blog standard</a></li>
                            <li><a href="blog-details.html">blog details</a></li>
                          </ul>
                        </li>
                        <li className="dropdown"><a href="/jddj">pages</a>
                          <ul>
                            <li><a href="single-service.html">service single</a></li>
                            <li><a href="team-details.html">Team Profile</a></li>
                          </ul>
                        </li>
                        <li><a href="contact.html">contact</a></li>
                      </ul> */}
                    </div>

                  </nav>

                  {/* <!-- Main Menu End--> */}
                  {/* <!-- Menu Serch Box--> */}
                  {/* <div className="nav-search">
                    <button className="fa fa-search"></button>
                    <form action="#" className="hide">
                      <input type="text" placeholder="Search" className="searchbox" required="" />
                      <button type="submit" className="searchbutton fa fa-search"></button>
                    </form>
                  </div> */}

                </div>

                {/* <div className="menu-right d-none d-lg-flex align-items-center">
                  <a href="contact.html" className="login">Login <i className="fas fa-lock"></i></a>
                  <a href="contact.html" className="theme-btn style-two">sign up <i className="fas fa-arrow-right"></i></a>
                </div> */}
              </div>
            </div>
          </div>
          {/* <!--End Header Upper--> */}
        </header>
        {/* <!--====== Header Part End ======--> */}
        {/* <!--====== Hero Section Start ======--> */}
        <section className="hero-section-three rel z-2 pt-235 rpt-150 pb-130 rpb-100">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-11">
                <div className="hero-content-three rpt-15 rmb-75">
                  <h1 className="mb-15 wow fadeInUp delay-0-2s">{t('p1.1')}</h1>
                  <p className="wow fadeInUp delay-0-4s">{t('p1.2')}</p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="hero-image-three overlay wow fadeInLeft delay-0-4s">
                  <img src="assets/images/hero/hero-three.svg" alt="Hero" />
                </div>
              </div>
            </div>
          </div>
          <img className="dots-shape" src="assets/images/shapes/dots.png" alt="Shape" />
          <img className="tringle-shape" src="assets/images/shapes/tringle.png" alt="Shape" />
          <img className="close-shape" src="assets/images/shapes/close.png" alt="Shape" />
        </section>
        {/* <!--====== Hero Section End ======--> */}
        {/* <!--====== Solutions Section Start ======--> */}
        <section className="solutions-section-three text-white text-center rel bg-blue pt-130 rpt-100 z-1 pb-75 rpb-45" style={{ backgroundImage: "url(assets/images/shapes/solutions-bg-dots.png)" }}>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-6 col-lg-8 col-md-10">
                <div className="section-title mb-75">
                  <span className="sub-title">{t('p1.3')}</span>
                  <h2>{t('p1.4')}</h2>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-xl-4 col-md-6">
                <div className="solution-item-three wow fadeInUp delay-0-2s">
                  <i className="far fa-compass"></i>
                  <h3><a href="single-service.html">{t('p1.5')}</a></h3>
                  <p>{t('p1.6')}</p>
                </div>
              </div>
              <div className="col-xl-4 col-md-6">
                <div className="solution-item-three wow fadeInUp delay-0-4s">
                  <i className="far fa-map"></i>
                  <h3><a href="single-service.html">{t('p1.7')}</a></h3>
                  <p>{t('p1.8')}</p>
                </div>
              </div>
              <div className="col-xl-4 col-md-6">
                <div className="solution-item-three wow fadeInUp delay-0-6s">
                  <i className="fas fa-shield-alt"></i>
                  <h3><a href="single-service.html">{t('p1.9')}</a></h3>
                  <p>{t('p1.10')}</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!--====== Solutions Section End ======--> */}
        {/* <!--====== About Section Start ======--> */}
        <section className="about-section-three rel z-1 pt-130 rpt-100">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-7 col-lg-6">
                <div className="about-image rmb-55 wow fadeInLeft delay-0-2s">
                  <img src="assets/images/about/about-two.svg" alt="About" />
                </div>
              </div>
              <div className="col-xl-5 col-lg-6">
                <div className="about-content-three wow fadeInRight delay-0-2s">
                  <div className="section-title mb-25">
                    <h2>{t('p1.11')}</h2>
                  </div>
                  <p>{t('p1.12')}</p>
                  <ul className="list-style-one mt-25 mb-35">
                    <li>Websites</li>
                    <li>Apps</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!--====== About Section End ======--> */}
        {/* <!--====== Browser Support Start ======--> */}
        <section className="browswr-support-section rel z-1 py-130 rpy-100">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-5 col-lg-6">
                <div className="browswr-support-content rmb-55 wow fadeInRight delay-0-2s">
                  <div className="section-title">
                    <h2>{t('p1.13')}</h2>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="solution-item-two">
                        <i className="fas fa-check"></i>
                        <h4>{t('p1.14')}</h4>
                        <p>{t('p1.15')}</p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="solution-item-two color-two">
                        <i className="fas fa-check"></i>
                        <h4>{t('p1.16')}</h4>
                        <p>{t('p1.17')}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-7 col-lg-6">
                <div className="browswr-support-image text-lg-right wow fadeInLeft delay-0-2s">
                  <img src="assets/images/about/borwser-support.svg" alt="Browser Support" />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!--====== Browser Support End ======--> */}
        {/* <!--====== Newsletter Section Start ======--> */}
        <section className="newsletter-section-two mt-30 rmt-0 rel z-2">
          <div className="container">
            <div className="newsletter-inner style-two bg-gray bgs-cover text-white rel z-1">
              <div className="row align-items-center align-items-xl-start">
                <div className="col-lg-6">
                  <div className="newsletter-content p-60 wow fadeInUp delay-0-2s">
                    <div className="section-title mb-30">
                      <span className="sub-title">UX</span>
                      <h2>{t('p1.18')}</h2>
                    </div>
                    <p>{t('p1.19')}</p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="newsletter-images wow fadeInUp delay-0-4s">
                    <img src="assets/images/newsletter/newsletter-two.png" alt="Newsletter" />
                    <img src="assets/images/newsletter/circle.png" alt="shape" className="circle slideUpRight" />
                    <img src="assets/images/newsletter/dots.png" alt="shape" className="dots slideLeftRight" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!--====== Newsletter Section End ======--> */}
        {/* <!--====== Services Section Start ======--> */}
        <section className="services-section-three bg-lighter rel z-1 pt-250 pb-100 rpb-70">
          <div className="container">
            <div className="row justify-content-center text-center">
              <div className="col-xl-7 col-lg-8 col-md-10">
                <div className="section-title mt-100 rmt-70 mb-55">
                  <span className="sub-title">{t('p1.3')}</span>
                  <h2>{t('p1.20')}</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-4 col-md-6">
                <div className="service-item wow fadeInUp delay-0-2s">
                  <i className="flaticon-file"></i>
                  <div className="content">
                    <h3><a href="single-service.html">{t('p1.21')}</a></h3>
                    <p>{t('p1.22')}</p>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-md-6">
                <div className="service-item wow fadeInUp delay-0-6s">
                  <i className="flaticon-security"></i>
                  <div className="content">
                    <h3><a href="single-service.html">{t('p1.23')}</a></h3>
                    <p>{t('p1.24')}</p>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-md-6">
                <div className="service-item wow fadeInUp delay-1-2s">
                  <i className="flaticon-analytics"></i>
                  <div className="content">
                    <h3><a href="single-service.html">{t('p1.25')}</a></h3>
                    <p>{t('p1.26')}</p>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-md-6">
                <div className="service-item wow fadeInUp delay-0-4s">
                  <i className="flaticon-responsive-design"></i>
                  <div className="content">
                    <h3><a href="single-service.html">{t('p1.27')}</a></h3>
                    <p>{t('p1.28')}</p>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-md-6">
                <div className="service-item wow fadeInUp delay-0-8s">
                  <i className="flaticon-puzzle"></i>
                  <div className="content">
                    <h3><a href="single-service.html">{t('p1.29')}</a></h3>
                    <p>{t('p1.30')}</p>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-md-6">
                <div className="service-item wow fadeInUp delay-1-0s">
                  <i className="flaticon-badge"></i>
                  <div className="content">
                    <h3><a href="single-service.html">{t('p1.31')}</a></h3>
                    <p>{t('p1.32')}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!--====== Services Section End ======--> */}
        {/* <!--====== Feedback Section Start ======--> */}
        {/* <section className="feedback-section-three bg-blue rel z-1 py-115 rpy-85" style={{ backgroundImage: "url(assets/images/feedbacks/feedback-bg.png)" }}>
          <div className="container">
            <div className="row">
              <div className="col-xl-4">
                <div className="feedback-three-content mt-15 text-white wow fadeInUp delay-0-2s">
                  <div className="section-title mb-25">
                    <span className="sub-title">Clients Feedback</span>
                    <h2>What Our Customer ’s Say About Us</h2>
                  </div>
                  <p>Sed ut perspiciatis unde omnis natus error voluptatem accusantium doloremque laudantium totam rem aperias eaque quae ainvtore veritatis et quasi architecto beatae vitae dicta sunt explicabo enim spsam</p>
                  <div className="slider-dots-area mt-25"></div>
                </div>
              </div>
              <div className="col-xl-8">
                <div className="feedback-three-wrap">
                  <div className="feedback-item-two wow fadeInUp delay-0-4s">
                    <img src="assets/images/client-logos/client-logo-white1.png" alt="Logo" />
                    <p>Sed ut perspiciatis unde omniste natus errors voluptatem accusantium doloreue laudantium <b>eaque ipsauae abtore</b> verita architecto beatae vitae dicta sunxplica nemo enimsam voluptatem quia natur autodit aut fugit</p>
                    <div className="feedback-author">
                      <i className="flaticon-quote-1"></i>
                      <div className="author-content">
                        <h3>Randall L. Hamby</h3>
                        <span>Senior Manager</span>
                      </div>
                    </div>
                  </div>
                  <div className="feedback-item-two wow fadeInUp delay-0-6s">
                    <img src="assets/images/client-logos/client-logo-white2.png" alt="Logo" />
                    <p>Sed ut perspiciatis unde omniste natus errors voluptatem accusantium doloreue laudantium <b>eaque ipsauae abtore</b> verita architecto beatae vitae dicta sunxplica nemo enimsam voluptatem quia natur autodit aut fugit</p>
                    <div className="feedback-author">
                      <i className="flaticon-quote-1"></i>
                      <div className="author-content">
                        <h3>Lillie R. Burkhalter</h3>
                        <span>Senior Manager</span>
                      </div>
                    </div>
                  </div>
                  <div className="feedback-item-two wow fadeInUp delay-0-8s">
                    <img src="assets/images/client-logos/client-logo-white3.png" alt="Logo" />
                    <p>Sed ut perspiciatis unde omniste natus errors voluptatem accusantium doloreue laudantium <b>eaque ipsauae abtore</b> verita architecto beatae vitae dicta sunxplica nemo enimsam voluptatem quia natur autodit aut fugit</p>
                    <div className="feedback-author">
                      <i className="flaticon-quote-1"></i>
                      <div className="author-content">
                        <h3>Zelma K. Hall</h3>
                        <span>Senior Manager</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* <!--====== Feedback Section End ======--> */}
        {/* <!--====== Partner Section Start ======--> */}
        {/* <div className="partner-area-three text-center pt-130 rpt-100">
          <div className="container">
            <div className="row justify-content-around justify-content-lg-between mb-80 rmb-50">
              <div className="col-lg-2 col-md-4 col-6">
                <a className="partner-item-three wow fadeInUp delay-0-2s" href="project-details.html">
                  <img src="assets/images/partners/partner-three-white1.png" alt="Partner" />
                </a>
              </div>
              <div className="col-lg-2 col-md-4 col-6">
                <a className="partner-item-three wow fadeInUp delay-0-4s" href="project-details.html">
                  <img src="assets/images/partners/partner-three-white2.png" alt="Partner" />
                </a>
              </div>
              <div className="col-lg-2 col-md-4 col-6">
                <a className="partner-item-three wow fadeInUp delay-0-6s" href="project-details.html">
                  <img src="assets/images/partners/partner-three-white3.png" alt="Partner" />
                </a>
              </div>
              <div className="col-lg-2 col-md-4 col-6">
                <a className="partner-item-three wow fadeInUp delay-0-8s" href="project-details.html">
                  <img src="assets/images/partners/partner-three-white4.png" alt="Partner" />
                </a>
              </div>
              <div className="col-lg-2 col-md-4 col-6">
                <a className="partner-item-three wow fadeInUp delay-1-0s" href="project-details.html">
                  <img src="assets/images/partners/partner-three-white5.png" alt="Partner" />
                </a>
              </div>
            </div>
            <hr />
          </div>
        </div> */}
        {/* <!--====== Partner Section End ======--> */}
        {/* <!--====== Contact Section Start ======--> */}
        <section className="contact-section-two rel z-1 pt-115 rpt-85 pb-130 rpb-55">
          <div className="container">
            <div className="row justify-content-center text-center">
              <div className="col-xl-6 col-lg-8 col-md-10">
                <div className="section-title mb-50">
                  <span className="sub-title">{t('p1.33')}</span>
                  <h2>{t('p1.34')}</h2>
                </div>
              </div>
            </div>
            <div className="contact-information-area pb-50">
              <div className="row justify-content-center">
                <div className="col-lg-6 col-md-6">
                  <div className="contact-info-item wow fadeInUp delay-0-2s">
                    <i className="fas fa-envelope-open"></i>
                    <div className="contact-info-content">
                      <h3>{t('p1.35')}</h3>
                      <span><a href="mailto:support@gmail.com">support@trailor-soft.com</a></span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="contact-info-item wow fadeInUp delay-0-4s">
                    <i className="fas fa-phone"></i>
                    <div className="contact-info-content">
                      <h3>{t('p1.36')}</h3>
                      <span><a href="calto:+0123456789">+1 2622350871</a></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!--====== Contact Section End ======--> */}
        {/* <!--====== Blog Section Start ======--> */}
        {/* <section className="blog-section-two bg-lighter rel z-1 pt-130 rpt-100 pb-180 rpb-150">
          <div className="container">
            <div className="row">
              <div className="col-xl-4 col-lg-8 col-md-10">
                <div className="blog-two-section-content mb-55 wow fadeInUp delay-0-2s">
                  <div className="section-title mb-25">
                    <span className="sub-title">Latest News & Blog</span>
                    <h2>Get Our Every Single Update Latest News and Blog</h2>
                  </div>
                  <a href="blog.html" className="theme-btn style-three">View More News <i className="fas fa-arrow-right"></i></a>
                </div>
              </div>
              <div className="col-xl-4 col-md-6">
                <div className="blog-item-two wow fadeInUp delay-0-4s">
                  <div className="blog-two-image" style={{ backgroundImage: "url(assets/images/blog/blog-two.jpg)" }}></div>
                  <ul className="blog-meta">
                    <li><i className="far fa-calendar-alt"></i> <a href="blog-details.html">25 March 2022</a></li>
                    <li><i className="far fa-comments"></i> <a href="/jddj">Com (5)</a></li>
                  </ul>
                  <h3><a href="blog-details.html">Smashin Podcast Episode Web Frameworks Solve Vanilla</a></h3>
                  <p>On the other hand, we denounce with righteous indignation and dislike men who are so beguiled and demoralized the charms of pleasure</p>
                  <a href="blog-details.html" className="read-more">Read More <i className="fas fa-arrow-right"></i></a>
                </div>
              </div>
              <div className="col-xl-4 col-md-6">
                <div className="blog-item-two wow fadeInUp delay-0-6s">
                  <div className="blog-two-image" style={{ backgroundImage: "url(assets/images/blog/blog-two.jpg)" }}></div>
                  <ul className="blog-meta">
                    <li><i className="far fa-calendar-alt"></i> <a href="blog-details.html">25 March 2022</a></li>
                    <li><i className="far fa-comments"></i> <a href="/jddj">Com (5)</a></li>
                  </ul>
                  <h3><a href="blog-details.html">Designing Better Links Wesites And Emails A Guideline</a></h3>
                  <p>On the other hand, we denounce with righteous indignation and dislike men who are so beguiled and demoralized the charms of pleasure</p>
                  <a href="blog-details.html" className="read-more">Read More <i className="fas fa-arrow-right"></i></a>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* <!--====== Blog Section End ======--> */}
        {/* <!--====== Footer Section Start ======--> */}
        <footer className="footer-section footer-two bg-gray text-white rel z-1">
          <div className="container">

            <div className="call-to-action bg-blue bgs-cover text-white rel z-1">
              <div className="row align-items-center">
                <div className="col-xl-7 col-lg-6">
                  <div className="section-title mb-20">
                    <h2>{t('p1.38')}</h2>
                    <p>{t('p1.39')}</p>
                  </div>
                </div>
                {/* <div className="col-xl-5 col-lg-6">
                  <div className="call-to-action-btns text-xl-right mb-20">
                    <a href="contact.html" className="theme-btn style-two rmb-15">Get Started <i className="fas fa-arrow-right"></i></a>
                    <a href="about.html" className="theme-btn style-three rmb-15">Learn More <i className="fas fa-arrow-right"></i></a>
                  </div>
                </div> */}
              </div>
              <img className="white-circle" src="assets/images/shapes/white-circle.png" alt="White Circle" />
              <img className="white-dots slideUpRight" src="assets/images/shapes/white-dots.png" alt="shape" />
              <img className="white-dots-circle slideLeftRight" src="assets/images/shapes/white-dots-circle.png" alt="shape" />
            </div>

            <div className="row justify-content-between">
              <div className="col-xl-3 col-sm-6 col-7 col-small">
                <div className="footer-widget about-widget">
                  <div className="footer-logo mb-20">
                    <a href="index.html"><img src="assets/images/logos/tailorsoft-logo-white.png" alt="Logo" /></a>
                  </div>
                  <p><span>{t('p1.40')}</span></p>
                  {/* <a href="about.html" className="read-more">Read More <i className="fas fa-arrow-right"></i></a> */}
                </div>
              </div>
              {/* <div className="col-xl-2 col-sm-4 col-5 col-small">
                <div className="footer-widget link-widget">
                  <h4 className="footer-title">About</h4>
                  <ul className="list-style-two">
                    <li><a href="projects.html">Success History</a></li>
                    <li><a href="team-details.html">Meet The Team</a></li>
                    <li><a href="blog.html">Latest News</a></li>
                    <li><a href="contact.html">Need a Career?</a></li>
                    <li><a href="single-service.html">Popular Service</a></li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-4 col-md-8">
                <div className="footer-widget link-widget">
                  <h4 className="footer-title">Quick Links</h4>
                  <ul className="list-style-two two-column">
                    <li><a href="single-service.html">Software Solutions</a></li>
                    <li><a href="single-service.html">Market Analysis</a></li>
                    <li><a href="single-service.html">Web Development</a></li>
                    <li><a href="single-service.html">Digital Solutions</a></li>
                    <li><a href="single-service.html">Graphics Design</a></li>
                    <li><a href="single-service.html">Consultations</a></li>
                    <li><a href="single-service.html">Application Design</a></li>
                    <li><a href="single-service.html">Success Rate</a></li>
                    <li><a href="single-service.html">SEO Optimization</a></li>
                    <li><a href="single-service.html">User Research</a></li>
                  </ul>
                </div>
              </div> */}
              <div className="col-xl-3 col-md-4">
                <div className="footer-widget contact-widget">
                  <h4 className="footer-title">{t('p1.41')}</h4>
                  <ul className="list-style-three">
                    {/* <li><i className="fas fa-map-marker-alt"></i> <span>55 Main Street, New York</span></li> */}
                    <li><i className="fas fa-envelope-open"></i> <span><a href="mailto:support@gmail.com">support@tailor-soft.com</a></span></li>
                    <li><i className="fas fa-phone"></i> <span>{t('p1.42')} : <a href="callto:+12622350871">+1 2622350871</a></span></li>
                  </ul>
                  {/* <div className="social-style-one mt-25">
                    <a href="/jddj"><i className="fab fa-facebook-f"></i></a>
                    <a href="/jddj"><i className="fab fa-twitter"></i></a>
                    <a href="/jddj"><i className="fab fa-linkedin-in"></i></a>
                    <a href="/jddj"><i className="fab fa-instagram"></i></a>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="copyright-area text-center">
              <p>© {new Date().getFullYear()} <a href="index.html">Tailorsoft.</a> {t('p1.43')}</p>
            </div>
          </div>
          <img className="dots-shape" src="assets/images/shapes/dots.png" alt="Shape" />
          <img className="tringle-shape" src="assets/images/shapes/tringle.png" alt="Shape" />
          <img className="close-shape" src="assets/images/shapes/close.png" alt="Shape" />
          <img className="circle-shape" src="assets/images/shapes/circle.png" alt="Shape" />
        </footer>
        {/* <!--====== Footer Section End ======--> */}
      </div>
      {/* <!-- Scroll Top Button --> */}
      <button className="scroll-top scroll-to-target" data-target="html"><span className="fa fa-angle-up"></span></button>
    </>
  );
}

export default App;
